import React from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import './all-episodes.css';

import { APP_DATA } from '../../../podcast-data/data.js';
const { podcasts } = APP_DATA;

export function AllEpisodes() {
	return (
		<div className="AllEpisodes">
			<h1>All Episodes</h1>
			<List
				dataSource={ getEpisodesArray(podcasts) }
				renderItem={ renderEpisodesList }
			/>
		</div>
	)
}

function getEpisodesArray(episodes) {
	const {
		allIds,
		byId,
	} = episodes;

	if (!Array.isArray(allIds)) {
		return null;
	}

	return allIds.map(id => byId[id]);
}

function renderEpisodesList(episode) {
	return (
		<List.Item>
			<List.Item.Meta
				title={
					<Link to={`/episodes/${episode.id}`}>
						{ `Episode ${episode.id} - ${episode.title}` }
					</Link>
				}
				description={ `Released on ${episode.releaseDate}` }
			/>
		</List.Item>
	);
}
