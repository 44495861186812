import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import { Home } from '../home';
import { About } from '../about';
import { Episode } from '../episode';
import { AllEpisodes } from '../all-episodes';
import './main-content.css';

const { Content } = Layout;

export function MainContent() {
	return (
		<Content className="MainContent">
			<Route exact path="/" component={ Home } />
			<Route path="/about/" component={ About } />
			<Route exact path="/episodes/" component={ AllEpisodes } />
			<Route path="/episodes/:id" component={ Episode } />
		</Content>
	);
}
