export const episode8 = {
	id: 8,
	title: 'I Believe that Jesus Christ Brings Lasting Happiness',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!e9182a65499b194625f44ab9d5a1fe6d4e979f6c',
	releaseDate: 'February 18, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'Missionaries permitted to call home each week on P-Day',
					link: 'https://www.mormonnewsroom.org/article/missionaries-family-communication',
				},
				{
					text: `Sister Joy D. Jones, Primary General President, distributed funds earned by the “Light the World” giving machines.`,
					link: 'https://www.mormonnewsroom.org/article/church-leaders-distribute-donations-global-humanitarian-partners',
				},
				{
					text: 'Prophet Invites Arizonans to Turn Homes Into Sanctuaries of Faith',
					link: 'https://www.mormonnewsroom.org/article/prophet-invites-arizonians-turn-homes-sanctuaries-faith',
				},
				{
					text: 'FHE Lesson from LDS Living',
					link: 'http://www.ldsliving.com/FHE-Let-Your-Light-Shine/s/90146',
				},
				{
					text: 'If You Don’t Like It, Change It - Coach Carrie Roberts',
					link: 'https://speeches.byu.edu/talks/carrie-roberts_if-you-dont-like-it-change-it/',
				}
			]
		},
		lesson: {
			title: '“Blessed are Ye”',
			dates: 'February 18 - February 24',
		},
		interview: {
			name: 'Brandon Ruiz',
			bullets: [
				{
					text: `Brandon Ruiz is a world champion wrester and grappler, author, father, coach, public speaker, and works as a landscape architect with a firm who works on projects (temples) for the Church of Jesus Christ of Latter-day Saints. He tells about some of his experiences competing at a world level and some of the special moments he has had designing landscape for temples close to his heart. `
				},
				{
					text: `Charles Duhigg - Smarter Faster Better`,
					link: 'https://charlesduhigg.com/books/smarter-faster-better/',
				},
				{
					text: `Brandon's Website`,
					link: `https://www.ruizcombatgrappling.com/`,
				},
				{
					text: `email info@ruizcombatgrappling.com`,
				},
				{
					text: `Brandon's Book: Champions Find a Way`,
					link: `https://www.amazon.com/Champions-Find-Way-Become-Champion/dp/1497416310/ref=sr_1_1?keywords=champions+find+a+way&qid=1550455052&s=gateway&sr=8-1`,
				}
			]
		}
	}
};
