export const episode11 = {
	id: 11,
	title: 'I Believe In The Apostles Of Jesus Christ',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!3c9d6af3dc9a038c9f336f760cacaa4701bf91af',
	releaseDate: 'March 11, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'On International Women’s Day 2019, a Message of Love From Africa',
					link: 'https://www.mormonnewsroom.org/article/international-womens-day-2019-africa-church-leaders',
				},
				{
					text: 'Prophet to Dedicate Rome Italy Temple',
					link: 'https://www.mormonnewsroom.org/article/prophet-dedicate-rome-italy-temple'
				},
				{
					text: 'Groundbreakings Announced for Two South American Temples',
					link: 'https://www.mormonnewsroom.org/article/groundbreakings-announced-for-two-south-american-temples'
				},
				{
					text: 'Public Invited to Tour Lisbon Portugal Temple',
					link: 'https://www.mormonnewsroom.org/article/public-invited-to-tour-lisbon-portugal-temple'
				},
				{
					text: 'Frankfurt Germany Temple Opens for Public Tours',
					link: 'https://www.mormonnewsroom.org/article/public-invited-to-tour-the-frankfurt-germany-temple'
				},
				{
					text: 'Changes to Emphasize the Correct Name of the Church of Jesus Christ',
					link: 'https://www.mormonnewsroom.org/article/church-name-alignment'
				},
				{
					text: 'Rome Italy Temple Is Dedicated ',
					link: 'https://www.mormonnewsroom.org/article/rome-temple-dedication'
				},
				{
					text: 'Prophet Meets Pope Francis at the Vatican',
					link: 'https://www.mormonnewsroom.org/article/prophet-meets-pope-francis-vatican'
				},
				{
					text: `Two Apostles Lead a Virtual Tour of the Rome Italy Temple`,
					link: 'https://www.mormonnewsroom.org/article/virtual-tour-rome-italy-temple'
				},
				{
					text: `On the Eve of the Rome Temple Dedication, Prophet Encourages Italian Youth`,
					link: `https://www.mormonnewsroom.org/article/rome-temple-dedication-youth-devotional`
				}
			]
		},
		lesson: {
			title: 'These Twelve Jesus Sent Forth',
			dates: 'March 11 - March 17',
		},
		interview: {
			name: 'No Interview this week'
		}
	}
};
