import React from 'react';
import {
	Collapse,
	Icon,
	Divider,
} from 'antd';
import { APP_DATA } from '../../../podcast-data/data.js';
import './episode.css';

const Panel = Collapse.Panel;
const { podcasts } = APP_DATA;

export function Episode(props) {
	const { match: { params: { id } } } = props;
	const episode = podcasts.byId[id];

	if (!episode) {
		window.location = '/episodes/';
		return;
	}

	const {
		showNotes: {
			news,
			lesson,
			interview,
		},
		title,
		url,
	} = episode;

	return (
		<div className="Episode">
			<h1>{ `Episode ${ id }: "${ title }"` }</h1>
			<iframe
				sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups"
				scrolling="no"
				title={ title }
				width="100%" height="185" frameBorder="0"
				src={ url }
			/>
			<Divider>Show Notes</Divider>
			<Collapse bordered={ false } activePanel={ null }>
				<Panel header={<span><Icon type="read" /> Church News</span>} key="news">
					<ul>
						{ renderBulletPoints(news.bullets) }
					</ul>
				</Panel>

				<Panel header={<span><Icon type="book" />{` Come, Follow Me 2019: "${ lesson.title }" (${ lesson.dates })`}</span>} key="study">
					<ul>
						{ renderBulletPoints(lesson.bullets) }
					</ul>
				</Panel>

				<Panel header={<span><Icon type="team" />{ ` Interview: ${ interview.name }` }</span>} key="interview">
					<ul>
						{ renderBulletPoints(interview.bullets) }
					</ul>
				</Panel>
			</Collapse>
		</div>
	);
}

function renderBulletPoints(bullets) {
	if (!bullets) {
		return null;
	}

	let bulletPoints = [];

	bullets.forEach((bullet) => {
		const {
			text,
			link,
			subPoints
		} = bullet;

		bulletPoints.push(
			<li key={ text }>
				<p>
					{ text }
					{ link && ` | ` }
					{ link && (
						<a href={ link } target="_blank" rel="noopener noreferrer">
							<Icon type="link" />
						</a>
					) }
				</p>
				{ subPoints && (
					<ul>
						{ renderBulletPoints(subPoints) }
					</ul>
				) }
			</li>
		);
	});

	return bulletPoints;
}
