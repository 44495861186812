export const episode1 = {
	id: 1,
	title: 'Genesis and Learning',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL',
	releaseDate: 'December 30, 2018',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'President Nelson emphasizes the importance of the name of the Church',
					link: 'https://www.lds.org/general-conference/2018/10/the-correct-name-of-the-church?lang=eng'
				},
				{
					text: 'Dress code changes were announced for sister missions',
					link: 'https://www.mormonnewsroom.org/article/dress-standards-updated-for-sister-missionaries'
				},
				{
					text: 'New Ministering program replaces home teaching',
					link: 'https://www.lds.org/church/news/ministering-to-replace-home-and-visiting-teaching?lang=eng'
				},
				{
					text: '19 new temples were announced: Mendoza, Argentina; Salvador, Brazil; Yuba City, California; Phnom Penh, Cambodia; Praia, Cape Verde; Yigo, Guam; Puebla, Mexico; Auckland, New Zealand; Lagos, Nigeria; Davao, Philippines; San Juan, Puerto Rico; and Washington County, Utah',
					link: 'https://www.mormonnewsroom.org/2018-year-in-review-the-church-of-jesus-christ-of-latter-day-saints/article/temple-building-accelerates-2018'
				},
				{
					text: 'The Mormon Tabernacle Choir changes its name to "The Tabernacle Choir at Temple Square"',
					link: 'https://www.mormonnewsroom.org/article/world-renowned-mormon-tabernacle-choir-changes-name'
				},
				{
					text: 'Changes to Church pageants are announced',
					link: 'https://www.mormonnewsroom.org/article/statement-local-celebrations-pageants-october-2018'
				},
				{
					text: `The Hymnal and Children's songbook to be revised`,
					link: 'https://www.mormonnewsroom.org/article/latter-day-saint-hymnbook-and-childrens-songbook-revised'
				},
				{
					text: 'Age changes for youth class advancement and Aaronic Priesthood ordinations',
					link: 'https://www.mormonnewsroom.org/article/age-changes-youth-progression-ordination-announced'
				}
			]
		},
		lesson: {
			title: 'We Are Responsible for Our Own Learning',
			dates: 'December 31 - January 6',
			bullets: [
				{
					text: 'To truly learn from the Savior, I must accept His invitation: "Come, follow me"',
					link: null
				},
				{
					text: 'I am responsible for my own learning',
					link: null
				},
				{
					text: 'I need to know the truth for myself',
					link: null
				},
				{
					text: 'What should I do when I have questions?',
					link: null
				},
				{
					text: 'President Nelson: "Opening Remarks"',
					link: 'https://www.lds.org/general-conference/2018/10/opening-remarks?lang=eng'
				},
				{
					text: 'Elder Bednar: "Seek Learning By Faith"',
					link: 'https://youtu.be/D2h82dGQ834'
				}
			]
		},
		interview: {
			name: 'Marianne Muthreich Southworth',
			bullets: [
				{
					text: 'Born in Rosenberg, Germany'
				},
				{
					text: 'Worked in Dansig, Germany for a florist at 15 years old'
				},
				{
					text: 'Avoided boarding the Wilhelm Gustloff, a German ocean liner that was sunk by a Soviet submarine on January 30, 1945. An estimate 9,000 passengers were killed in that incident, making it the greatest maritime disaster in history'
				}
			]
		}
	}
};
