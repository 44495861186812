import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import ReactGA from 'react-ga';
import { BelievePodcast } from './believe-podcast.react';

ReactDOM.render(
	<BelievePodcast />,
	document.getElementById('root')
);

ReactGA.initialize('UA-133685292-1');
ReactGA.pageview(window.location.pathname + window.location.search);

