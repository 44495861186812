import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';

import { SideNav } from './components/side-nav';
import { MainContent } from './components/main-content';
import { MainHeader } from './components/main-header';
import { MainFooter } from './components/main-footer';

import './believe-podcast.css';

export class BelievePodcast extends Component {
	render() {
		return (
			<Router>
				<Layout className="MainLayoutContainer">
					<SideNav />
					<Layout>
						<MainHeader />
						<MainContent />
						<MainFooter />
					</Layout>
				</Layout>
			</Router>
		);
	}
}
