export const episode3 = {
	id: 3,
	title: 'We Have Come to Worship Him',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!b5dea3f983287b3bc41b875ac937524a04974486',
	releaseDate: 'January 13, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'Free Family History Library Classes and Webinars',
					link: 'https://www.lds.org/church/news/free-family-history-library-classes-and-webinars-for-january-2019?lang=eng',
					subPoints: [
						{
							text: 'The focus of instruction this month will be on beginners'
						},
						{
							text: 'Learn how to use the powerful, free resources of FamilySearch and cool tips and tricks',
							link: 'https://www.familysearch.org'
						},
						{
							text: 'Participants can attend in person or online'
						},
						{
							text: 'No registration is required'
						},
						{
							text: 'If you are unable to attend a class in person or online, most sessions are recorded and can be viewed later online at your convenience',
							link: 'http://bit.ly/2mBC2eX'
						}
					]
				},
				{
					text: `President Nelson's daughter passed away`,
					link: 'https://www.thechurchnews.com/members/2019-01-12/daughter-of-president-nelson-dies-after-courageous-battle-with-cancer-48759',
					subPoints: [
						{
							text: 'Wendy Nelson Maxfield'
						},
						{
							text: 'She died Friday, January 11, 2019 at her home, after a courageous battle with cancer. She was 67.'
						}
					]
				},
			]
		},
		lesson: {
			title: 'We Have Come to Worship Him',
			dates: 'January 14 - January 20',
			bullets: [
				{
					text: `Jesus Christ was born in humble circumstances`,
					subPoints: [
						{
							text: `In Luke 1: 1-7, we read of Mary and Joseph journeying to Bethlehem. It is interesting to note that, Inns in the days of Mary and Joesph were not like traditional hotels that we have today, but more like extra rooms to stay in. Kind of like a biblical AirBnB. (Read from Commentary on New Testament Luke 1:7)`
						},
						{
							text: `In Brad Wilcox’s book "Because of the Messiah in a Manger" he gives insight into the meaning behind “swaddling clothes” the “manger” and the “inn”. He explains that the inn explained in Luke 2 is more correctly translated at “Guest Room” As the census was taking place in the city, many people from all over had come to stay in Bethlehem so more than likely every inn was full.`
						}
					]
				},
				{
					text: `There are many witnesses of the birth of Christ`,
					subPoints: [
						{
							text: `Now Jesus has been born`
						},
						{
							text: `Simeon and Anna come to visit them and testify of Jesus`
						},
						{
							text: `And we read in Matthew 2 the wise men (not sure how many), came as many as 2 years after Christ was born`
						},
						{
							text: `Each of the visitors to the Baby Jesus offer their own individual lessons. Read through them and see what sticks out to you`
						}
					]
				},
				{
					text: `Parents can receive revelation to protect their families`,
					subPoints: [
						{
							text: `In Matt: 2:13 Joseph is told by an Angel to flee to Egypt to protect his family`
						},
						{
							text: `In the manual it reads: “Joseph never could have done what he was asked to do — protect Jesus in His childhood — without heaven’s help"`
						}
					]
				},
				{
					text: `Even as a youth, Jesus was focused on doing His Father’s will`
				},
				{
					text: `What is the Joseph Smith Translation?`
				},
			]
		},
		interview: {
			name: 'Marianne Muthreich Southworth',
			bullets: [
				{
					text: `Marianne has gone back to stay with her Mother, her brothers and her aunt ( who is a member of the communist party). She is now looking for a job.`
				},
				{
					text: `The man at the town hall who she inquires about getting a job was a drunk`
				},
				{
					text: `She explained to me some of the unspeakable things that happened after the war to this man’s wife and daughter that caused the man’s wife to take her own life.`
				},
				{
					text: `She lives and works in town and after a while decides she must leave. SHE WANTS TO BE A SPY! She get's a work certificate and sets out to be a russian or german spy. (she didn't care)`
				},
				{
					text: `She winds up getting another florist job and begins looking to join a church. A co-worker was a member of the Church of Jesus Christ of Latter-day Saints and invited her to attend her branch, which met a ways away in the city, above a tavern. She reluctantly accepted.`
				},
				{
					text: `As she listened to the branch president speak she said she felt like it was the right church for her. She left thinking deeply about the message, the feelings, and the Church.`
				},
				{
					text: `In a silent prayer she asked is this it? and saw a sign reading "Dead End." She thought about it a while and felt that this was the church for her.`
				},
				{
					text: `She was told she had to wait one year to be baptized, which she was not happy about cause she said she would never leave. "They could have baptized me right then and there," she said.`
				}
			]
		}
	}
};
