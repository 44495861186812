import { episode1 } from './episode-1';
import { episode2 } from './episode-2';
import { episode3 } from './episode-3';
import { episode4 } from './episode-4';
import { episode5 } from './episode-5';
import { episode6 } from './episode-6';
import { episode7 } from './episode-7';
import { episode8 } from './episode-8';
import { episode9 } from './episode-9';
import { episode10 } from './episode-10';
import { episode11 } from './episode-11';
import { episode12 } from './episode-12';
import { episode13 } from './episode-13';

export const APP_DATA = {
	podcasts: {
		byId: {
			1: episode1,
			2: episode2,
			3: episode3,
			4: episode4,
			5: episode5,
			6: episode6,
			7: episode7,
			8: episode8,
			9: episode9,
			10: episode10,
			11: episode11,
			12: episode12,
			13: episode13
		},
		allIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
	}
};
