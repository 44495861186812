export const episode5 = {
	id: 5,
	title: 'I Believe in the Baptism of Jesus Christ',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!5d60799166007ef1845e81b7c40a9358b7465537',
	releaseDate: 'January 28, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'St. George Temple Closing for Renovation:',
					link: 'https://www.mormonnewsroom.org/article/st--george-temple-closing-november-4--2019',
					subPoints: [
						{
							text: 'The St. George Utah Temple of The Church of Jesus Christ of Latter-day Saints will close later this year for a major renovation.'
						},
						{
							text: 'The temple will close November 4, 2019'
						},
						{
							text: 'The St. George Utah Temple was originally dedicated in April 1877, the first temple completed in Utah. It was rededicated in November 1975 after it was remodeled.'
						},
						{
							text: 'Founding Fathers and George Washington appeared to Wilford Woodruff in a dream asking about their temple work needing to be completed.'
						}
					]
				},
				{
					text: `Ground Breaking for Bangkok Temple:`,
					link: 'https://www.mormonnewsroom.hk/eng/article/church-leaders-break-ground-for-temple-in-bangkok',
					subPoints: [
						{
							text: 'The Church of Jesus Christ of Latter-day Saints broke ground for its first temple in Thailand Saturday, January 26, 2019.'
						},
						{
							text: '“Today was an historic day for the Church in Thailand,” Elder Evans said. “For more than 50 years, the faithful saints in Thailand have waited and prayed for this day. The construction and ultimate completion of the temple will become a blessing for not only the members of the Church, but also for all who see the beauty of the temple and feel of the peace that will be found on this sacred ground.”'
						},
						{
							text: 'The Church will build a majestic 44,405-square-foot, six-story temple with multiple spires reaching heavenward. It will be a place of beauty and peace where all can come.'
						},
						{
							text: 'In addition to the temple, a 91,370-square-foot building will feature two religious meetinghouses, seminary and institute facilities, Church offices and housing. Substantial underground parking is also planned at the site.'
						},
						{
							text: 'Today, there are more than 22,000 Latter-day Saints in Thailand, organized in 42 congregations. This temple will serve Church members in Thailand and other nearby countries.'
						}
					]
				},
				{
					text: 'Typhoon Relief Efforts in Mariana Islands:',
					link: 'https://www.mormonnewsroom.org/article/church-assists-typhoon-relief-efforts-northern-mariana-islands',
					subPoints: [
						{
							text: 'Church leaders met with Red Cross officials in Saipan January 24, 2019, to discuss the use of the donated items to support the residents following the natural disaster.'
						},
						{
							text: 'The Church has donated more than $1 million to the Red Cross in disaster relief after Typhoon Yutu slammed into the islands of Saipan and Tinian on October 25, 2018, the strongest tropical cyclone on record to ever hit the area. The islands, located in the central Pacific, are a commonwealth of the United States.'
						},
						{
							text: 'Residents of the isolated island of Tinian and the southern villages of Saipan experienced sustained winds of 170 miles per hour with gusts of more than 200 miles per hour. All 1,100 homes on the island of Tinian were damaged or destroyed.'
						},
						{
							text: '“The Red Cross is proud to count LDS Charities as a partner as we work together to provide thousands of people throughout the Northern Mariana Islands with much-needed support,” said Don Herring, chief development officer at the American Red Cross.'
						}
					]
				},
				{
					text: 'A Message of Gratitude and a Promise for the Women of the Church in 2019:',
					link: 'https://www.lds.org/blog/a-message-of-gratitude-and-a-promise-for-the-women-of-the-church-in-2019?lang=eng',
					subPoints: [
						{
							text: '“My dear and precious sisters, four months have now passed since I extended my invitation to you to help gather scattered Israel. Do you recall how I told you that gathering Israel is “the greatest challenge, the greatest cause, and the greatest work on earth today”? Many of you have seized this challenge with great vigor. I wish to tell you that the Lord loves you and is pleased with every effort you make.”'
						},
						{
							text: 'On my Instagram and Facebook accounts, I asked to hear about your experiences. I was overwhelmed to see that more than 10,000 women responded! My heart was touched by what you shared.'
						}
					]
				},
				{
					text: 'BYU Speech by Elder Lawrence E Corbridge:',
					link: 'https://speeches.byu.edu/talks/lawrence-corbridge_stand-for-ever/',
					subPoints: [
						{
							text: 'Tasked with reading all of the anti-mormon literature out there.'
						},
						{
							text: 'Primary Questions and Secondary Questions'
						}
					]
				},
				{
					text: 'Urdaneta Philippines Temple: Ground Broken by Church Leaders:',
					link: 'https://www.mormonnewsroom.ph/article/urdaneta-philippines-temple--ground-broken-by-church-leaders',
					subPoints: [
						{
							text: 'Elder Jeffrey R. Holland of the Quorum of the Twelve Apostles presided at the ceremony.'
						}
					]
				}
			]
		},
		lesson: {
			title: 'Prepare Ye the Way of the Lord',
			dates: 'January 28 – February 3',
			bullets: [
				{
					text: `Who was Mark?`,
					subPoints: [
						{
							text: `Missionary companion to Peter, Paul and other missionaries.`
						},
						{
							text: `Scholars believe Peter instructed Mark to record the events of the Savior’s life.`
						},
						{
							text: 'Mark’s gospel was likely written before the other three.'
						},
						{
							text: 'BD: His object is to describe our Lord as the incarnate Son of God, living and acting among men.'
						},
						{
							text: 'BD: Died by Martyrdom'
						}
					]
				},
				{
					text: `Repentance is a mighty change of mind and heart.`,
					subPoints: [
						{
							text: `True repentance is “a change of mind, a fresh view about God, about oneself, and about the world… [it means] a turning of the heart and will to God” - Bible Dictionary, “Repentance”`
						},
						{
							text: `John the Baptist is preaching repentance. He uses fruit and wheat to emphasize the importance of repenting. Repentance is necessary to receive Christ.`
						}
					]
				},
				{
					text: `Who were the Pharisees and Sadducees?`,
					subPoints: [
						{
							text: `Pharisees were members of the Jewish religouhs party who prided themselvees on strict observance of Mosaic Law.`
						},
						{
							text: `Sadducees were a wealthy Jewish class with significant religious and political influence. They did not believe in the doctrine of resurrection.`
						}, 
						{
							text: `Many members of both parties refused to accept the message of God’s prophet, John the Baptist.`
						}
					]
				},
				{
					text: `Jesus Christ was baptized to “fulfill all righteousness.”`,
					subPoints: [
						{
							text: `The manual asks you to reflect on your baptism and compare what happened during your baptism to the baptism of Jesus.`
						},
						{
							text: `2 Nephi 31:4-9`
						}
					]
				},
				{
					text: `Does the Holy Bible teach that the member of the Godhead are separate beings?`,
					subPoints: [
						{
							text: 'Matthew 3: 13-17'
						}
					]
				}
			]
		},
		interview: {
			name: 'Marianne Muthreich Southworth'
		}
	}
};
