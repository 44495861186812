import React from 'react';
import { Card, Icon } from 'antd';
import './home.css';

export function Home() {
	return (
		<div className="Home">
				<img alt="Sermon on the Mount" className="Home__image" src="https://i.imgur.com/BuAgYy3.jpg" />

			<div className="Home__cards">
				<Card hoverable={ true } className="Home__card Home__card--news">
					<Icon className="Home__cardIcon" type="read" />
					<h1>Church News</h1>
					<p>President Nelson, the First Presidency and the Quorum of the Twelve Apostles are sustaining changes so fast, it can be hard to keep up. Listen in weekly for a quick digest.</p>
				</Card>
				<Card hoverable={ true } className="Home__card Home__card--study">
					<Icon className="Home__cardIcon" type="book" />
					<h1>Come, Follow Me</h1>
					<p>Each week, I will break down the lesson from <em>Come, Follow Me - For Individuals and Families</em>. I hope it helps you in your studying; if not, it is definitely helping me.</p>
				</Card>
				<Card hoverable={ true } className="Home__card Home__card--interview">
					<Icon className="Home__cardIcon" type="team" />
					<h1>Interviews</h1>
					<p>There are so many members of the Church of Jesus Christ of Latter-day Saints with amazing, faith promoting stories. Tune it to hear how they made it through tough times, and what they do to keep their faith.</p>
				</Card>
			</div>
		</div>
	);
}
