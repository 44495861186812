export const episode10 = {
	id: 10,
	title: 'I Believe that Christ can Heal Me',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!9ef8f6300a7f2a40e356db070d8e635eb3940d4f',
	releaseDate: 'March 4, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'Public Invited to Tour Lisbon Portugal Temple',
					link: 'https://www.mormonnewsroom.org/article/public-invited-to-tour-lisbon-portugal-temple',
				},
				{
					text: 'Two Church Leaders Embark on Outreach Efforts in West Africa',
					link: 'https://www.mormonnewsroom.org/article/church-leaders-outreach-west-africa'
				},
				{
					text: 'Neighbors Learn of Pocatello Idaho Temple Plan',
					link: 'https://www.mormonnewsroom.org/article/neighbors-learn-of-pocatello-idaho-temple-plan'
				},
				{
					text: 'Latter-day Saints Around the World Country Newsroom Websites March 1, 2019',
					link: 'https://www.mormonnewsroom.org/article/latter-day-saints-around-the-world-country-newsroom-websites-march-1-2019'
				},
				{
					text: 'Temple Rededications Announced for May 2019',
					link: 'https://www.mormonnewsroom.org/article/temple-rededications-announced-for-may-2019'
				},
				{
					text: 'New Safety Videos Will Help Missionaries Focus on Serving Jesus Christ',
					link: 'https://www.mormonnewsroom.org/article/new-safety-videos-missionaries-focus-on-serving-jesus-christ'
				},
				{
					text: 'Church Donates $2 Million to the International African American Museum',
					link: 'https://www.mormonnewsroom.org/article/church-donation-2-million-international-african-american-museum-center-family-history'
				},
				{
					text: `Latter-day Saints Join Other Faiths to Change Women's Lives`,
					link: 'https://www.mormonnewsroom.org/article/latter-day-saints-join-other-faiths-to-change-womens-lives'
				},
			]
		},
		lesson: {
			title: 'Thy Faith Hath Made Thee Whole',
			dates: 'March 4 - March 10',
		},
		interview: {
			name: 'No Interview this week'
		}
	}
};
