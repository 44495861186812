import React from 'react';
import {
	Card,
	Icon,
} from 'antd';

import './about.css';

export function About() {
	return (
		<div className="About">
			<Card
				actions={ cardActions }
				className="About__card"
				title="About Me"
			>
				<p>My name is Conrad Southworth, and I should definitely find someone else to write this section.</p>
				<p>Does anyone like writing their own "About Me" section? I don't think so.</p>
				<p>
					I'm 29 years old, and I moved from Colorado to Utah when I was 10.
					I grew up in a non-conventional Latter-day Saint Family; my mother is a convert to the Church of Jesus Christ
					of Latter-day Saints, and my father was born into it.
					I would say my family was less-active until we moved to Utah--growing up surrounded by other, active members of
					the Church made it easier to be active and to participate in Mutual and such.
				</p>
				<p>
					I am a husband of one, a father of two (one boy, one girl), and I work in IT as a Systems Engineer / DevOps Engineer /
					Systems Administrator / Meat Chef / (insert more titles here). Working at a start-up means you wear all sorts of hats,
					depending on the day.
				</p>
				<p>
					I am a purple belt in Brazilian Jiu-Jitsu, training out of Lehi Judo Club...I could talk about how awesome jiu-jitsu is for a long time,
					but suffice it to say: everyone should it. It's a great sport, and I love it.
				</p>
			</Card>

			<Card className="About__card" bordered={ false }>
				<img className="About__img" alt="Conrad Southworth" src='http://ibelieveinchrist.net.s3.amazonaws.com/media/conrad_aboutme.jpg' />
			</Card>
		</div>
	)
}

const cardActions = [
	<a href="https://twitter.com/con_radjiujitsu" target="_blank" rel="noopener noreferrer">
		<Icon type="twitter" />
	</a>,
	<a href="https://itunes.apple.com/us/podcast/id1448184206" target="_blank" rel="noopener noreferrer">
		<Icon type="apple" />
	</a>,
	<a href="https://facebook.com/IBelieveInChristPodcast/" target="_blank" rel="noopener noreferrer">
		<Icon type="facebook" />
	</a>,
	<a href="https://www.instagram.com/ibelieveinchristpodcast/" target="_blank" rel="noopener noreferrer">
		<Icon type="instagram" />
	</a>,
	<a href="mailto:podcast@ibelieveinchrist.net">
		<Icon type="form" />
	</a>
];
