export const episode9 = {
	id: 9,
	title: 'I Believe that through Christ I can be Better',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!0886c3b77a48923b22e3d273cef927f7650e5a7a',
	releaseDate: 'February 25, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'LDS Charities Releases 2018 Annual Report',
					link: 'https://www.mormonnewsroom.org/article/lds-charities-releases-2018-annual-report',
				}
			]
		},
		lesson: {
			title: 'He Taught them as One Having Authority',
			dates: 'February 25 - March 5',
		},
		interview: {
			name: 'No Interview this week'
		}
	}
};
