import React from 'react';
import { Link } from 'react-router-dom';
import {
	Layout,
	Menu,
} from 'antd';
import { APP_DATA } from '../../../podcast-data/data.js';
import './side-nav.css';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

export function SideNav() {
	return (
		<Sider
			breakpoint="lg"
			collapsedWidth="0"
			theme="dark"
		>
			<div className="SideNav__spacer">
				<img alt="I Believe in Christ Podcast" className="SideNav__logo" src="https://i.imgur.com/ZOPrQDy.png" />
			</div>
			<Menu theme="dark" mode="inline">
				<Item key="home">
					<Link to="/">Home</Link>
				</Item>
				<Item key="about">
					<Link to="/about/">About</Link>
				</Item>
				<SubMenu
					key="episodes"
					title={ <span>Episodes</span> }
				>
					{ createEpisodeSubMenu(APP_DATA)  }
				</SubMenu>
			</Menu>
		</Sider>
	);
}

function createEpisodeSubMenu(episodes) {
	const {
		podcasts: {
			byId,
			allIds,
		}
	} = episodes;
	let items = [<Item key="all"><Link to="/episodes/">All Episodes</Link></Item>];

	if (!byId || !allIds.length) {
		return;
	}

	allIds.forEach((id) => {
		const episode = byId[id];

		items.push(
			<Item key={ episode.id }>
				<Link to={ `/episodes/${episode.id}` }>
					{ `Episode ${episode.id}` }
				</Link>
			</Item>
		)
	});

	return items;
}
