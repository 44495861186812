import React from 'react';
import { Layout } from 'antd';
import './main-header.css';

const { Header } = Layout;

export function MainHeader() {
	return (
		<Header className="MainHeader">
			<h1 className="MainHeader__text">I Believe in Christ Podcast</h1>
		</Header>
	);
}
