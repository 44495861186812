export const episode2 = {
	id: 2,
	title: 'Be It unto Me according to Thy Word',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!3a4225364c96f63c9a215b141be47bfae356f443',
	releaseDate: 'January 6, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'January 2019 Worldwide Devotional For Young Adults announced',
					link: 'https://www.lds.org/church/events/january-2019-worldwide-devotional-for-young-adults?lang=eng'
				},
				{
					text: 'The First Presidency Releases Statement on Temples',
					link: 'https://www.lds.org/church/news/first-presidency-releases-statement-on-temples?lang=eng'
				},
				{
					text: 'The Church announced 4 new missions and 2019 mission boundary realignments',
					link: 'https://www.lds.org/church/news/church-announces-four-new-missions-2019-boundary-realignments?lang=eng'
				}
			]
		},
		lesson: {
			title: 'Be It unto Me according to Thy Word',
			dates: 'January 7 - January 13',
			bullets: [
				{
					text: 'Who were Matthew and Luke?',
				},
				{
					text: 'Why did the Savior need to be born of a mortal mother and immortal Father?',
				},
				{
					text: 'President Nelson: "Constancy amid Change"',
					link: 'https://www.lds.org/general-conference/1993/10/constancy-amid-change?lang=eng'
				},
				{
					text: `God's blessings come in His own time`,
				},
				{
					text: 'Belief in the Gospel promises blessings',
				},
				{
					text: `Mary learned that God's blessings were different from what she had in mind`,
				},
				{
					text: `Mary testifies of Jesus Christ's mission`
				},
				{
					text: `Mary gives examples what what Jesus Christ will do in His life`
				},
				{
					text: `Be Humble: Luke 1:51-53`,
					link: 'https://www.lds.org/scriptures/nt/luke/1.37?lang=eng'
				},
				{
					text: `Ideas for families:`,
					subPoints: [
						{
							text: `Jesus Christ's lineage is traced back to David`
						},
						{
							text: `This is important because it was prophesied that He would be a descendant of King David`
						},
						{
							text: `Use Mary's and Gabriel's examples to "Fear not" when the Lord asks hard things of us`
						}
					]
				},
			]
		},
		interview: {
			name: 'Marianne Muthreich Southworth',
			bullets: [
				{
					text: `Marianne's boss shaves her head so she looks like a boy to avoid the Russian soldiers taking advantage of her`
				},
				{
					text: `She lives in coal cellar where no one knew where she was`
				},
				{
					text: `Her boss's brother hangs himself in that coal cellar where she was hiding`
				},
				{
					text: `She works for the Russians in Dunsig, posing as a boy`
				},
				{
					text: `She gets a travel pass from a Polish secretary that worked for the Russians and takes a cattle train west. She would board the train during the day, and sleep on the street near the train station at night`
				},
				{
					text: `Death and disease were everywhere in Germany. Many of Stalin's soldier had contracted venereal diseases, lice, and other diseases. Marianne said she couldn't drink water, it was all polluted`
				},
				{
					text: `She makes it to Berlin, determined to start a normal life. She searches for a job, but is turned down because there are "too many refugees" in the city, and is told to go home`
				},
				{
					text: `She pled with the man every day, and eventually got a job with a nearby florist`
				},
				{
					text: `She starts looking for her "tribe"--her family--by posting messages on a wall of missing people. A woman recognizes Marianne's mother's name, and Marianne gets clearance to cross the border and is told where she could find her parents`
				},
				{
					text: `Despite her friends wanting her to stay, Marianne feels the need to leave Berlin. She heads back east, and is eventually able to find her mother and two brothers`
				},
				{
					text: `Marianne, her mother, and her two brothers live in a small apartment above a tavern. She decides to stay and help, so she travels to Berlin to gather her belongings and returns home`
				}
			]
		}
	}
};
