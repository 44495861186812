export const episode4 = {
	id: 4,
	title: 'John the Beloved',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!157502119f03de92065b9994880297613fe7d05a',
	releaseDate: 'January 21, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'Rome Italy Temple:',
					link: 'https://www.youtube.com/watch?v=eEwbYzI-pyk',
					subPoints: [
						{
							text: 'Lutheran Church approved The Church of Jesus Christ of Latter-day Saints to do special scans of their statues of the twelve apostles which were then replicated by a machine and carved out of marble.'
						},
						{
							text: 'Olive trees from the old farm were preserved, and ancient olive trees from northern Italy, ranging in age from 400 to 500 years old, are planted in the piazza.'
						},
						{
							text: 'Truman G. Madsen: The Olive Press: “I think I had a prejudice that the setting of the Savior’s life really was not significant, that the meaning of his life and his words was what mattered, and that the events in the environment and circumstances of the time were not crucial. After many visits since, for we have both visited and lived there, I am of the contrary opinion: that he cared very much about the setting and that meaning is lodged, still, in the very rocks, in the very mountains, in the very trees of Israel.” '
						}
					]
				},
				{
					text: `Elder and Sister Renlund Discuss Faith and Doubt in Worldwide Broadcast:`,
					link: 'https://youtu.be/HkcS-v_DolQ',
					subPoints: [
						{
							text: 'Lost at sea, picked up by a boat with a tattered sailor with a mostly broken boat.'
						},
						{
							text: 'Sister Renlund: “The sole purpose of The Church of Jesus Christ of Latter-day Saints is to help Heavenly Father and Jesus Christ in Their work to bring to pass the eternal life of God’s children, Those who serve in the Church, though not perfect, are essential to help and encourage us along the covenant path."'
						}
					]
				},
				{
					text: 'Bre Lasley on “All In”',
					link: 'http://www.ldsliving.com/Listen-Bre-Lasley-the-Survivor-of-an-Attempted-Murder-Testifies-That-Heavenly-Father-Dispatched-Earthy-Angels-to-Save-Her/s/90122',
					subPoints: [
						{
							text: 'Tragic and inspiring story about Bre Lasley and her sister fighting off an attacker in their home.'
						}
					]
				},
				{
					text: 'Gladys Knight to Sing National Anthem at 2019 Super Bowl',
					link: 'http://www.ldsliving.com/Gladys-Knight-to-Sing-National-Anthem-at-2019-Super-Bowl/s/90127/?utm_source=ldsliving&utm_medium=sidebar&utm_campaign=popular',
					subPoints: [
						{
							text: 'Latter-day Saint Gladys Knight, the Empress of Soul, will be singing at the 2019 Super Bowl on February 3, 2019.'
						}
					]
				},
				{
					text: 'President Nelson Brings Hope to Californians Caught in 2018 Wildfires',
					link: 'https://www.mormonnewsroom.org/article/president-nelson-brings-hope-to-californians-caught-in-2018-wildfires',
					subPoints: [
						{
							text: 'November 8, 2018, heavily damaged the town of Paradise and surrounding areas. At least 86 people were killed, and tens of thousands of people were displaced, including 1,400 Latter-day Saints.'
						},
						{
							text: 'When asked about the loss of his daughter, President Nelson replied: “Everybody has challenges and if you want to feel better, serve somebody else.”'
						}
					]
				},
				{
					text: 'Urdaneta Philippines Temple: Ground Broken by Church Leaders:',
					link: 'https://www.mormonnewsroom.ph/article/urdaneta-philippines-temple--ground-broken-by-church-leaders',
					subPoints: [
						{
							text: 'Elder Jeffrey R. Holland of the Quorum of the Twelve Apostles presided at the ceremony.'
						}
					]
				},
				{
					text: 'Elder Christofferson Discusses Lessons From Watergate With Famed Journalist Bob Woodward',
					link: 'https://www.youtube.com/watch?v=paHFD-S4QY8',
					subPoints: [
						{
							text: 'Elder Christofferson speaks about integrity and trust.'
						},
						{
							text: '“We’ve got to feel accountability — I think always. At least to God if nowhere else”'
						}
					]
				},
				{
					text: 'Elder Uchtdorf spoke at a Brigham Young University devotional in Provo, Utah, Tuesday, January 15, 2019.',
					link: 'https://speeches.byu.edu/talks/dieter-f-uchtdorf_can-you-hear-the-music/',
					subPoints: [
						{
							text: 'Elder Uchtdorf said recognizing the voice of God may take time and commitment. “Some seem to be born with a testimony of the gospel and a sensitivity to spiritual things. For others, belief comes slowly, and the process may feel difficult or frustrating."'
						},
						{
							text: 'Elder Uchtdorf encouraged those seeking answers to prayers to be patient. “This light will come in God’s time, not ours. … It will come in God’s way. … It comes as we believe.”'
						},
						{
							text: '“If God answered the prayers only of the perfectly obedient, He would have to shut down heaven’s entire prayer-answering department,” stressed Elder Uchtdorf. “God reaches out to us even when we are struggling. When we are failing. In fact, we might say that He reaches out to us especially when we are struggling and failing.”'
						}
					]
				}

			]
		},
		lesson: {
			title: 'We Have Found the Messiah',
			dates: 'January 21 - January 27',
			bullets: [
				{
					text: `Who was John?`,
					subPoints: [
						{
							text: `1 of the twelve apostles`
						},
						{
							text: `Him and his brother James were fisherman before Jesus called them to the apostleship.`
						},
						{
							text: 'He was one of three apostles with Jesus on the mount of Transfiguration, and one of the three with Jesus in the Garden of Gethsemane.'
						},
						{
							text: 'Referred to as John the Beloved, and John the Revelator.'
						},
						{
							text: 'He appeared with Peter and James to restore the Melchizedek Priesthood in 1830.'
						},
						{
							text: '(D&C 7) John did not die, but was translated and was allowed to remain on the earth to serve the Lord until the second coming.'
						}
					]
				},
				{
					text: `Jesus Christ was “in the beginning with God.”`,
					subPoints: [
						{
							text: `JST John 1-5: John starts by referencing the Premortal Christ and not just talking about his mortal ministry. `
						},
						{
							text: `Manual asks: why is it important to know about His premortal work?`
						},
						{
							text: `To me it is important to know that 1. There was a plan, nothing was done on accident or on a whim. 2. Jesus chose to perform his premortal work and give all glory to God. 3. Jesus was the perfect example in the Premortal existence and he was a perfect example for each of us in his mortal ministry.`
						}
					]
				},
				{
					text: `Disciples of Jesus Christ bear witness of Him.`,
					subPoints: [
						{
							text: `John describes that John the baptist bore testimony of Jesus Christ. John then bears testimony of Jesus Christ and includes truths about Jesus and his works.`
						},
						{
							text: `(manual) Consider writing down your witness of Jesus Christ.`,
							subPoints: [
								{
									text: `What would you want to share?`
								},
								{
									text: `What experiences have helped you come to know and follow the Savior?`
								},
								{
									text: 'Who might be blessed by hearing your testimony?'
								}

							]
						}
					]
				},
				{
					text: `What does it mean to “become the sons [and daughters] of God”? (John 1:12)`,
					subPoints: [
						{
							text: `We are all spirit sons and daughters of God. But because of our imperfections, our sins. We are separated from God and we need Jesus Christ to cleanse us and restore us, so we may receive our eternal inheritance. To be heir of all that the father has.`
						}
					]
				},
				{
					text: `Has anyone seen God?`,
					subPoints: [
						{
							text: 'JST John 1:18: Yes and he bears record of the Son.'
						}
					]
				},
				{
					text: 'Who is Elias, and who is “that prophet”?',
					subPoints: [
						{
							text: 'Elias makes reference to Elijah, who would come to restore the hearts of the children to the fathers and the hearts of the fathers to the children. “That prophet” references Jesus Christ from a prophecy in Deuteronomy. '
						}
					]
				},
				{
					text: 'Ideas for Family Scripture Study and Family Home Evening:',
					subPoints: [
						{
							text: 'Help you family visualize “light”. You can use a flash light while studying and take turns holding it and explaining how the Savior is the Light of your life.'
						},
						{
							text: 'Share and Discuss John the Baptist’s testimony. What did he testify of? What do you learn from the people he describes? '
						},
						{
							text: 'Read about Nathanael gaining his testimony of Jesus Christ and reflect on how you gained a testimony.'
						}
					]
				}
			]
		},
		interview: {
			name: 'Marianne Muthreich Southworth',
			bullets: [
				{
					text: `Marianne has been baptized and confirmed a member of the Church of Jesus Christ of Latter-day Saints`
				},
				{
					text: `She is called to serve as the Primary President, and shares a story about serving in her calling and doing her duties, no matter what.`
				},
				{
					text: `After waiting two years to be able to immigrate to America, there is confusion from her American sponsor on the day she is supposed to leave.`
				},
				{
					text: `Marianne sets sail with the travel agent that was responsible for getting her ticket and heads to Amsterdam in hopes that by the time she arrives her ticket will be worked out and she can go to America`
				},
				{
					text: `Marianne is the last to board the boat and is barely aloud to cross the Atlantic. After learning she is "Mormon" the ticketing agency trusts that her ticket will be paid once she is in America and things are worked out`
				},
				{
					text: `Marianne gets to New York City and is put in a hotel on Broadway to wait for her train that leaves the next day for Arizona, where her sponsor lives.`
				},
				{
					text: `The confustion came from her sponsor no longer needing Marianne's assistance. However, now that she had arrived they ageed to keep their end of the bargain.`
				},
				{
					text: `After arriving in America, the USA stopped letting Eastern Eurpoeans immigrate, until they had been fully vetted. If Marriane had stayed in Europe, she wouldn not had been able to come to America for quite a while longer.`
				}
			]
		}
	}
};
