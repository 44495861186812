export const episode6 = {
	id: 6,
	title: 'I Believe in the Ministry of Jesus Christ',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!dbcf41ff5b23201d95dfd00bd0f617366c18f309',
	releaseDate: 'February 4, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'JustServe Helps Silicon Slopes Provide 1 Million Meals to Hungry Children:',
					link: 'https://www.mormonnewsroom.org/article/justserve-helps-silicon-slopes-provide-meals-to-hungry-children',
				},
				{
					text: `New Planning Tool Available for Prospective Missionaries:`,
					link: 'https://www.mormonnewsroom.org/article/new-tool-available-prospective-missionaries',
				},
				{
					text: 'Latter-day Saints Around the World:',
					link: 'https://www.mormonnewsroom.org/article/latter-day-saints-around-the-world-country-newsroom-websites-february-1-2019',
				},
				{
					text: 'Asia Temple Will Close for Renovation:',
					link: 'https://www.mormonnewsroom.org/article/asia-temple-will-close',
				},
				{
					text: 'Temple Groundbreakings Announced for Pocatello and Guam:',
					link: 'https://www.mormonnewsroom.org/article/temple-groundbreakings-announced--pocatello-guam',
				}
			]
		},
		lesson: {
			title: '“The Spirit of the Lord Is upon Me”',
			dates: 'February 4 - February 10',
		},
		interview: {
			name: 'Monica Budge',
			bullets: [
				{
					text: `A mother, grandmother, full-time student, and full-time employee, we discuss trials she has had to endure, her incredible faith in the Plan of Salvation, and how through thick and thin, family is here to lift us up.`
				}
			]
		}
	}
};
