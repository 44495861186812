export const episode13 = {
	id: 13,
	title: 'I Believe That Christ Is The Bread Of Life',
    url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!65cef35a6568a91a333378223cc8db85d863f5ac',
	releaseDate: 'March 25, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: `Hamilton Latter-day Saints Welcome Muslim Neighbours`,
					link: 'https://www.mormonnewsroom.org.nz/article/2000-people-attend-devotional-in-hamilton-with-muslim-neighbors',
				},
				{
					text: `Seminary Program to Change Its Study Schedule and Curriculum in 2020`,
					link: 'https://www.mormonnewsroom.org/article/seminary-program-change-2020'
				},
				{
					text: `Church Hosts Interfaith Musical Tribute in Tabernacle`,
					link: `https://www.mormonnewsroom.org/article/interfaith-musical-tribute-2019-tabernacle`
				},
			]
		},
		lesson: {
			title: 'Be Not Afraid',
			dates: 'March 25 - March 31',
		},
		interview: {
			name: 'No Interview this week'
		}
	}
};
