import React from 'react';
import { Layout } from 'antd';
import './main-footer.css';

const { Footer } = Layout;

export function MainFooter() {
	return (
		<Footer>
			<div className="MainFooter">
				<div className="MainFooter__column MainFooter__column--copyright">
					<strong>&copy; 2019 Conrad Southworth</strong> |
					<strong> RSS</strong>
					<a href="http://ibelieveinchrist.net/ibelieveinchrist.xml" target="_blank" rel="noopener noreferrer">
						<img className="MainFooter__rssIcon" alt="RSS feed" src="https://i.imgur.com/6P5SDas.png" />
					</a>
				</div>

				<div className="MainFooter__column MainFooter__column--disclaimer">
					This podcast is produced by members of the Church of Jesus Christ of Latter-day saints,
					but <strong>is not an official podcast of the Church</strong>. All thoughts, opinions, and views
					expressed are <em>strictly</em> those of the authors and speakers.
				</div>

				<div className="MainFooter__column MainFooter__column--podcasts">
					<a href="https://itunes.apple.com/us/podcast/id1448184206" target="_blank" rel="noopener noreferrer">
						<img alt="Listen on Apple Podcasts" className="MainFooter__podcastImg" src="https://i.imgur.com/jMvlzRt.png" />
					</a>
				</div>
			</div>
		</Footer>
	);
}
