export const episode7 = {
	id: 7,
	title: 'I Believe that God Sent his Only Begotten Son',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!3eb8e4918fbf5222354a4cb4e994d8ecb915c198',
	releaseDate: 'February 11, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: 'Elder Rasband spoke to 50,000 church educators, be “First Responders” to students considering Suicide.',
					link: 'https://www.mormonnewsroom.org/article/apostle-tells-educators-be-first-responders-students-considering-suicide',
				},
				{
					text: `Argentina MTC will close in July 2019.`,
					link: 'https://www.mormonnewsroom.org/article/argentina-mission-training-center-to-close-in-july',
				},
				{
					text: 'Groundbreaking for the Praia Cabo Verde Temple (West Coast of Africa) set for May 4, 2019.',
					link: 'https://www.mormonnewsroom.org/article/groundbreaking-date-set-praia-cabo-verde-temple',
				},
				{
					text: 'Elder Soares spoke at BYU about not allowing yourself to be pulled from the path of righteousness.',
					link: 'https://www.mormonnewsroom.org/article/elder-soares-remembering-savior-happiness',
				},
				{
					text: 'Article on LDS Living about Come, Follow Me and People experiences. ',
					link: 'http://www.ldsliving.com/Come-Follow-Me-Tips/s/90237',
				}
			]
		},
		lesson: {
			title: 'Ye Must Be Born Again',
			dates: 'February 11 - February 17',
		},
		interview: {
			name: 'No Interview This Week'
		}
	}
};
