export const episode12 = {
	id: 12,
	title: 'I Believe In The Teachings Of Jesus Christ',
	url: 'https://embed.radiopublic.com/e?if=i-believe-in-christ-podcas-WlkNmL&ge=s1!a86b8c2a62b596169308345733da29d8f709237c',
	releaseDate: 'March 18, 2019',
	showNotes: {
		news: {
			bullets: [
				{
					text: `President Nelson talks 'unprecedented future' of Church following Rome Italy Temple dedication`,
					link: 'https://www.thechurchnews.com/leaders-and-ministry/2019-03-15/president-nelson-talks-unprecedented-future-of-church-following-rome-italy-temple-dedication-49233?fbclid=IwAR0OcvZAeURxnoUBS1DhzY6JtSpN6lY8pRnpgaM-hxKwy0K2Df3hdppIQcM',
				},
				{
					text: `Creating a Scripture Study Plan + More Amazing Things You Didn't Know You Could Do with the Gospel Library App`,
					link: 'http://www.ldsliving.com/7-Things-You-Didn-t-Know-You-Could-Do-with-the-Gospel-Library-App/s/78638'
				},
				{
					text: `Groundbreaking Held for Pocatello Idaho Temple`,
					link: `https://www.mormonnewsroom.org/article/groundbreaking-held-pocatello-idaho-temple`
				},
				{
					text: 'Statement of Support for Muslim Communities and All Others Impacted by Christchurch Shootings',
					link: 'https://www.mormonnewsroom.org.nz/article/statement-of-support-for-muslim-communities-and-all-others-impacted-by-christchurch-shootings'
				},
				{
					text: 'The World Is Invited to President Nelson’s 95th Birthday Celebration',
					link: 'https://www.mormonnewsroom.org/article/president-nelson-95th-birthday-celebration-invitation'
				},
				{
					text: 'Apostles Testify of Jesus Christ in Rome',
					link: 'https://www.mormonnewsroom.org/article/apostles-testify-jesus-christ-rome'
				},
				{
					text: 'Sister Bingham and Sister Cordon Conclude West Africa Tour in Côte d’Ivoire',
					link: 'https://www.mormonnewsroom.org/article/sister-bingham-sister-cordon-conclude-west-africa-tour'
				},
			]
		},
		lesson: {
			title: 'Who Hath Ears to Hear, Let Him Hear',
			dates: 'March 18 - March 24',
		},
		interview: {
			name: 'No Interview this week'
		}
	}
};
